import React from 'react';
import 'twin.macro';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Fade from 'react-reveal/Fade';

import { Heading, Paragraph } from '../atoms';

const testimonial = [
  {
    id: 1,
    company: 'Upanal CNC Solutions',
    image: '/images/upanal-logo.png',
  },
  {
    id: 1,
    company: 'Olive Abodes',
    image: '/images/olive-adobe-logo.png',
  },
  {
    id: 3,
    company: 'Olive Constructions',
    image: '/images/olive-cons-logo.png',
  },
  {
    id: 4,
    company: '1 Percentage',
    image: '/images/percentage-logo.png',
  },
];
const Testimonial = ({ isHomePage }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };
  return (
    <div tw="mx-auto mt-32 max-w-screen-lg px-6 text-center">
      <Heading fontMedium={isHomePage}>
        {isHomePage ? 'Top Brands Believe in us' : 'Our clients'}
      </Heading>
      {!isHomePage && (
        <Paragraph>
          With grins of fulfillment, here’s what our clients’ had to say almost
          our administrations
        </Paragraph>
      )}
      <div tw="max-w-screen-lg mx-auto mb-20">
        {/* md:grid-cols-2 */}
        <div tw="mt-24">
          <Slider {...settings}>
            {testimonial.map((item) => (
              <Fade bottom delay={150}>
                <div key={item.id} tw="text-center">
                  <img src={item.image} alt="user" tw="mx-auto h-20 mb-4" />
                  {!isHomePage && (
                    <div tw="mt-3">
                      <p tw="text-secondary">{item.company}</p>
                    </div>
                  )}
                </div>
              </Fade>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};
export default Testimonial;
